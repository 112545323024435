import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO, { seoPropsMapping } from "../components/seo"
import ReactPlayer from "react-player"
import MarketoForm from "../components/MarketoForm"
import { Link } from "gatsby"

const Gates = ({ setVisibility, setShowVideoBlock }) => {
  const triggerOnSuccess = () => {
    setVisibility(false)
    setShowVideoBlock(true)
    localStorage.setItem("key_to_gated", "true")
  }

  return (
    <div className="gatedBlockWrapper uk-dark uk-width-1-2">
      <div className="marketoWrapper">
        <h3>Please fill out this form to watch the webinar</h3>
        <Link
          id="form-close"
          to="/webinars"
          className="uk-close-large"
          type="button"
          data-uk-close
        ></Link>
        <MarketoForm formId="1571" triggerOnSuccess={triggerOnSuccess} />
      </div>
    </div>
  )
}

const WebinarTheatre = props => {
  const webinar = props.data.prismicWebinars
  const webinarsList = props.data.allPrismicWebinars
  const vimeoURL = "https://vimeo.com/" + webinar.data.vimeo_id

  const seo_description =
    webinar.data.description && webinar.data.description.text
      ? webinar.data.description.text.length > 160
        ? `${webinar.data.description.text.substring(0, 160)}...`
        : webinar.data.description.text
      : null

  const seoProps = seoPropsMapping(webinar.data, {
    seo_title: webinar.data.title,
    seo_description,
    image: webinar.data.thumbnail.fixed.src,
  })

  const [isVisible, setVisibility] = useState(false)

  const [showVideoBlock, setShowVideoBlock] = useState(!webinar.data.is_gated)

  useEffect(() => {
    setVisibility(localStorage.getItem("key_to_gated") !== "true")
    setShowVideoBlock(localStorage.getItem("key_to_gated") === "true" || !webinar.data.is_gated)
  }, [])

  return (
    <Layout>
      <SEO {...seoProps} />
      <div className="webinarsTheatre uk-dark">
        <Link className="form-close uk-close-large" to="/webinars" data-uk-close />
        <div className="uk-container">
          <div className="content">
            <h1 className="uk-margin-medium-bottom" style={{ color: "#4D13F2" }}>
              {webinar.data.title}
            </h1>
            <div className="uk-flex" style={{ gap: "15px" }}>
              <div
                className="uk-margin-small-top uk-margin-large-bottom uk-text-left uk-width-1-2"
                dangerouslySetInnerHTML={{ __html: webinar.data.description.html }}
              />
              {webinar.data.is_gated && isVisible && (
                <Gates setVisibility={setVisibility} setShowVideoBlock={setShowVideoBlock} />
              )}
              {showVideoBlock && (
                <div className="webinar-video-wrapper uk-width-1-2">
                  <ReactPlayer url={vimeoURL} controls={true} width="100%" height="100%" />
                </div>
              )}
            </div>
          </div>
          <div className="thumbnails uk-margin-large-bottom" data-uk-slider>
            <div className="uk-slider-container">
              <ul className="uk-slider-items uk-child-width-1-4@s uk-child-width-1-4@ uk-grid">
                {webinarsList.edges.map((webinar, i) => (
                  <div key={`webinar-${i}`}>
                    <div
                      className="thumbnail"
                      style={{
                        backgroundImage: `url(${webinar.node.data.thumbnail.fixed.src})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className="veil" data-uk-height-match=".veil">
                        <h5>{webinar.node.data.title}</h5>
                        <p>
                          <Link
                            to={`/webinars/${webinar.node.uid}`}
                            onClick={e => props.switchTheatres(e, i)}
                            className="uk-button uk-button-primary uk-border-pill"
                          >
                            View video
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </ul>
              <a
                href="#"
                className="webinars-nav uk-position-center-left-out"
                data-uk-slidenav-previous
                data-uk-slider-item="previous"
              ></a>
              <a
                href="#"
                className="webinars-nav uk-position-center-right-out"
                data-uk-slidenav-next
                data-uk-slider-item="next"
              ></a>
            </div>
          </div>
          <div className="uk-text-center uk-margin-large-bottom">
            <a href="/webinars">
              <span uk-icon="arrow-left"></span> Return to list
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default WebinarTheatre

export const query = graphql`
  query WebinarDetail($uid: String) {
    allPrismicWebinars(
      sort: { order: DESC, fields: data___publish_date }
      filter: { uid: { ne: $uid } }
    ) {
      edges {
        node {
          uid
          data {
            title
            description {
              html
            }
            vimeo_id
            thumbnail {
              fixed(width: 400) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
          }
        }
      }
    }
    prismicWebinars(uid: { eq: $uid }) {
      data {
        seo_title
        seo_description
        seo_keywords
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        is_gated
        vimeo_id
        title
        description {
          html
          text
        }
        thumbnail {
          fixed(width: 400) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
      }
      uid
    }
  }
`
